import "@/styles/globals.css";
import type { AppProps } from "next/app";
import Head from "next/head";
import { Inter } from "next/font/google";
import localFont from "next/font/local";
import { appWithTranslation } from "next-i18next";
import ToastsContainer from "@/components/toasts/ToastsContainer";

export const inter = Inter({ subsets: ["latin"], variable: "--font-inter" });
export const officina = localFont({
  src: "../styles/fonts/officina.woff2",
  variable: "--font-officina",
});

function App({ Component, pageProps }: AppProps) {
  return (
    <main
      className={`h-screen w-full max-h-screen max-w-full overflow-y-auto ${officina.variable} ${inter.variable} font-sans`}
    >
      <Head>
        <title>LichtBlick eMobility Zahlungen</title>
        <link href="/favicon.svg" rel="icon" type="image/svg+xml" />
      </Head>

      <ToastsContainer />

      <Component {...pageProps} />
    </main>
  );
}
export default appWithTranslation(App);
