import { useToasts } from "./state";
import Toast from "./Toast";

interface Props {}

export default function ToastsContainer({}: Props) {
  const { toasts } = useToasts((s) => s);

  return (
    <div className="fixed w-full flex flex-col gap-2 items-center h-full pointer-events-none p-2 z-40">
      {toasts.map((config) => (
        <Toast key={config.id} {...config} />
      ))}
    </div>
  );
}
