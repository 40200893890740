import { Icon } from "@phosphor-icons/react";
import clsx from "clsx";
import { HTMLProps, ReactNode } from "react";

export type MessageColor = "blue" | "yellow" | "red" | "green" | "gray";

interface Props extends HTMLProps<HTMLDivElement> {
  message?: ReactNode;
  icon?: Icon;
  color?: MessageColor;
  border?: boolean;
}

export default function Message({
  message,
  icon: Icon,
  color = "blue",
  border = false,
  className,
  children,
  ...props
}: Props) {
  return (
    <div
      className={clsx(
        "flex justify-center gap-1 md:gap-3 py-3",
        "w-full md:py-4 px-3 md:px-4 rounded-lg",
        {
          border,
          "bg-light-aqua border-medium-aqua text-dark-aqua": color === "blue",
          "bg-light-berry border-medium-berry text-dark-berry": color === "red",
          "bg-light-green border-medium-green text-dark-green":
            color === "green",
          "bg-light-orange border-medium-orange text-dark-orange":
            color === "yellow",
          "bg-gray-100 border-gray-300 text-gray-600": color === "gray",
        },
        className
      )}
      {...props}
    >
      {Icon !== undefined && (
        <Icon className={clsx("shrink-0 size-6 mt-0.5 md:mt-0")} />
      )}

      <div className={clsx("font-semibold md:text-base flex-1 text-left")}>
        {message}
        {children}
      </div>
    </div>
  );
}
